<template>
      <div class="page">
        <div class="policy">
          <h1 class="title main-title">Политика в отношении обработки персональных данных</h1>

          <div class="content">
            <div class="block">
              <div class="subtitle">Общие положения</div>

              <div class="text">
                  <div>
                    Настоящая политика обработки персональных данных составлена в соответствии с требованиями Федерального закона от 27.07.2006. №152-ФЗ «О персональных данных» и определяет порядок обработки персональных данных и меры по обеспечению безопасности персональных данных ООО "Интернет решения" (далее – Оператор).
                  </div>
                  <div>
                    Оператор ставит своей важнейшей целью и условием осуществления своей деятельности соблюдение прав и свобод человека и гражданина при обработке его персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
                  </div>
                  <div>
                    Настоящая политика Оператора в отношении обработки персональных данных (далее – Политика) применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта <a href="https://dataforce.io" target="_blank">https://dataforce.io</a>.
                  </div>
              </div>
            </div>

            <div class="block">
              <div class="subtitle">Основные понятия, используемые в Политике</div>

              <div class="text">
                <div>В политике Конфиденциальности DATAFORCE используются следующие основные понятия и определения:</div>

                <ul class="list">
                  <li>Автоматизированная обработка персональных данных – обработка персональных данных с помощью средств вычислительной техники;</li>
                  <li>Блокирование персональных данных – временное прекращение обработки персональных данных (за исключением случаев, если обработка необходима для уточнения персональных данных);</li>
                  <li>Веб-сайт – совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу <a href="https://dataforce.io" target="_blank">https://dataforce.io</a>;</li>
                  <li>Информационная система персональных данных — совокупность содержащихся в базах данных персональных данных, и обеспечивающих их обработку информационных технологий и технических средств;</li>
                  <li>Обезличивание персональных данных — действия, в результате которых невозможно определить без использования дополнительной информации принадлежность персональных данных конкретному Пользователю или иному субъекту персональных данных;</li>
                  <li>Обработка персональных данных – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных;</li>
                  <li>Оператор – государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а также определяющие цели обработки персональных данных, состав персональных данных, подлежащих обработке, действия (операции), совершаемые с персональными данными;</li>
                  <li>Персональные данные – любая информация, относящаяся прямо или косвенно к определенному или определяемому Пользователю веб-сайта <a href="https://dataforce.io" target="_blank">https://dataforce.io</a>;</li>
                  <li>Пользователь – любой посетитель веб-сайта <a href="https://dataforce.io" target="_blank">https://dataforce.io</a>;</li>
                  <li>Предоставление персональных данных – действия, направленные на раскрытие персональных данных определенному лицу или определенному кругу лиц;</li>
                  <li>Распространение персональных данных – любые действия, направленные на раскрытие персональных данных неопределенному кругу лиц (передача персональных данных) или на ознакомление с персональными данными неограниченного круга лиц, в том числе обнародование персональных данных в средствах массовой информации, размещение в информационно-телекоммуникационных сетях или предоставление доступа к персональным данным каким-либо иным способом;</li>
                  <li>Трансграничная передача персональных данных – передача персональных данных на территорию иностранного государства органу власти иностранного государства, иностранному физическому или иностранному юридическому лицу;</li>
                  <li>Уничтожение персональных данных – любые действия, в результате которых персональные данные уничтожаются безвозвратно с невозможностью дальнейшего восстановления содержания персональных данных в информационной системе персональных данных и (или) результате которых уничтожаются материальные носители персональных данных.</li>
                </ul>
              </div>
            </div>

            <div class="block">
              <div class="subtitle">Возможности Оператора</div>

              <div class="text">
                <div>Оператор может обрабатывать следующие персональные данные Пользователя:</div>

                <ul class="list">
                  <li>Фамилия, имя, отчество</li>
                  <li>Электронный адрес</li>
                  <li>Номера телефонов</li>
                  <li>Также на сайте происходит сбор и обработка обезличенных данных о посетителях (в т.ч. файлов «cookie») с помощью сервисов интернет-статистики (Яндекс Метрика и Гугл Аналитика и других).</li>
                </ul>

                <div class="additional">
                  <div>Вышеперечисленные данные далее по тексту Политики объединены общим понятием - Персональные данные.</div>
                </div>
              </div>
            </div>

            <div class="block">
              <div class="subtitle">Цели обработки персональных данных</div>

              <div class="text">
                <div>
                  Цель обработки персональных данных Пользователя — информирование Пользователя посредством отправки электронных писем; предоставление доступа Пользователю к сервисам, информации и/или материалам, содержащимся на вебсайте.
                </div>
                <div>
                  Также Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты <a href="mailto:manager@dataforce.io">manager@dataforce.io</a> с пометкой «Отказ от уведомлениях о новых продуктах и услугах и специальных предложениях».
                </div>
                <div>
                  Обезличенные данные Пользователей, собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователей на сайте, улучшения качества сайта и его содержания.
                </div>
              </div>
            </div>

            <div class="block">
              <div class="subtitle">Правовые основания обработки персональных данных</div>

              <div class="text">
                <div>
                  Оператор обрабатывает персональные данные Пользователя только в случае их заполнения и/или отправки Пользователем самостоятельно через специальные формы, расположенные на сайте <a href="https://dataforce.io" target="_blank">https://dataforce.io</a>. Заполняя соответствующие формы и/или отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.
                </div>
                <div>
                  Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).
                </div>
              </div>
            </div>

            <div class="block">
              <div class="subtitle">Порядок сбора, хранения, передачи и других видов обработки персональных данных</div>

              <div class="text">
                <div>
                  Безопасность персональных данных, которые обрабатываются Оператором,обеспечивается путем реализации правовых, организационных и технических мер, необходимых для выполнения в полном объеме требований действующего законодательства в области защиты персональных данных.
                </div>
                <div>
                  Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.
                </div>
                <div>
                  Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с исполнением действующего законодательства.
                </div>
                <div>
                  В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомления на адрес электронной почты Оператора <a href="mailto:manager@dataforce.io">manager@dataforce.io</a> с пометкой «Актуализация персональных данных».
                </div>
                <div>Срок обработки персональных данных является неограниченным.</div>
                <div>
                  Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору уведомление посредством электронной почты на электронный адрес Оператора <a href="mailto:manager@dataforce.io">manager@dataforce.io</a> с пометкой «Отзыв согласия на обработку персональных данных».
                </div>
                <br/>
                <div><strong>Сбор и использование информации</strong></div>
                <div>
                  Для лучшего опыта и для предоставления нашей Услуги мы можем потребовать от вас предоставить нам, прямо или косвенно, определенную личную информацию, включая, помимо прочего, имя пользователя и учетные данные Oauth2. Информация, которую мы запрашиваем, будет храниться нами и использоваться, как описано в этой политике конфиденциальности.
                </div>
                <div>
                  Приложение использует сторонние сервисы, которые могут собирать информацию, используемую для вашей идентификации. Ссылки на политики конфиденциальности сторонних поставщиков услуг, используемые DATAFORCE:
                </div>
                <div>
                  <a href="https://policies.google.com/privacy" target="_blank">Службы входа в Google</a>
                </div>
              </div>
            </div>

            <div class="block">
              <div class="subtitle">Трансграничная передача персональных данных</div>

              <div class="text">
                <div>
                  Оператор до начала осуществления трансграничной передачи персональных данных обязан убедиться в том, что иностранным государством, на территорию которого предполагается осуществлять передачу персональных данных, обеспечивается надежная защита прав субъектов персональных данных.
                </div>
                <div>
                  Трансграничная передача персональных данных на территории иностранных государств, не отвечающих вышеуказанным требованиям, может осуществляться только в случае наличия согласия в письменной форме субъекта персональных данных на трансграничную передачу его персональных данных и/или исполнения договора, стороной которого является субъект персональных данных.
                </div>
              </div>
            </div>

            <div class="block">
              <div class="subtitle">Заключительные положения</div>

              <div class="text">
                <div>
                  Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, обратившись к Оператору с помощью электронной почты <a href="mailto:manager@dataforce.io">manager@dataforce.io</a>.
                </div>
                <div>
                  В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией.
                </div>
                <div>
                  Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу <a href="https://dataforce.io/confidential" target="_blank">https://dataforce.io/confidential</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
export default {
  name: 'confidential',

  metaInfo() {
    return {
      title: 'Политика DATAFORCE в отношении обработки персональных данных',
      meta: [
        {
          name: 'description',
          content: 'DATAFORCE — Ознакомьтесь с политикой в отношении Ваших персональных данных'
        }
      ]
    }
  }
}
</script>

<style lang="scss" src="./confidential.scss" scoped></style>
